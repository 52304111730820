<template lang="">
  <div>
    <CurrencyConversion />
    <ReferenceDataSearch />
  </div>
</template>
<script>
export default {
  components: {
    ReferenceDataSearch: () => import('./ReferenceDataSearch.vue'),
    CurrencyConversion: () => import('./CurrencyConversion.vue'),
  },
}
</script>
<style lang="">

</style>
